@import 'src/styles/variables/_colors.scss';

.progressBar {
  background-color: $qm-gray-lighter;
  min-width: 100px;
  height: 6px;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;

  .selected {
    transition: width 0.25s;
    background: #4580ff;
    background: linear-gradient(5deg, #4580ff 0%, #6092ff 100%);
    width: 1%;
    height: 6px;
  }
}
