@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.auth-panel {
  .container {
    .content-row {
      .sso-login-page {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: center;
        height: 100%;

        .error-bag {
          max-width: 400px;
          margin: 1em auto 0 auto;

          .error-item {
            width: auto;
          }
        }
      }
    }
  }
}
