@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.auth-panel {
  width: 100%;
  height: 100vh;
  bottom: 0;
  top: 0;
  background: url('../../../../assets/images/login-bg.jpg');
  background-size: cover;
  text-align: center;

  font-family: 'Maison Neue';

  .exacture-logo {
    width: 300px;
    align-self: center;
  }

  h1 {
    font-weight: 800;
    font-size: 36px;
  }

  h2 {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
  }

  h3 {
    font-size: 28px;
    font-weight: 600;
    line-height: 36px;
  }

  h5 {
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 27px;
  }

  .instructions {
    color: $qm-color-text;
    font-family: 'MaisonNeue-Book';
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 22px;
    max-width: 360px;
    margin-bottom: 48px;
  }

  .container {
    $form-width: 300px;

    display: flex;
    flex-direction: row;
    height: 100%;

    .row {
      padding: $qm-padding-l;
      width: 50%;
      height: 100%;
    }

    .bg-row {
      color: $qm-white;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.38);

      .button-container {
        max-width: 200px;
      }

      h2 {
        margin-bottom: $qm-margin-s;
      }

      p {
        margin-bottom: $qm-margin-m;
      }
    }

    .content-row {
      background-color: white;
      padding: 60px 0 0 0;

      .footer-container {
        padding: 24px 0px;
        border-top: 1px solid $qm-gray-lighter;
        color: $qm-color-text;
        font-family: 'MaisonNeue-Book';
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0px;
        line-height: 22px;
      }

      .patent-container {
        padding: 0px 0px;
        border-top: 1px solid $qm-gray-lighter;
        color: $qm-color-text-secondary;
        font-family: 'MaisonNeue-Book';
        font-size: 10px;
        font-weight: 300;
        letter-spacing: 0.05em;
        line-height: 1.75em;
      }

      margin: auto;

      h3 {
        margin-bottom: $qm-margin-xs;
      }

      h5 {
        margin-bottom: $qm-margin-l;
      }

      .qm-button {
        width: $form-width;
      }

      .qm-input-container {
        margin-bottom: 20px;
        width: $form-width;
      }

      .invalid-feedback {
        width: $form-width;
      }
    }
  }
}
