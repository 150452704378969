@import '~styles/variables/colors';
@import '~styles/variables/sizes';

.work-object-workflow {
  h2 {
    margin-bottom: 48px;
  }
  .timeline-link {
    color: $qm-color-text;

    &:hover {
      color: $qm-color-text-link;
      text-decoration: underline;
    }
  }
  .timeline-item {
    &::before {
      border-color: $qm-gray-lighter;
    }
    &::after {
      background: $qm-gray-lighter;
    }

    &:last-of-type {
      &::after {
        bottom: 10px;
      }
      .timeline-new-subitem {
        bottom: 0;
      }
    }
  }

  // .timeline-new-subitem-dropdown {
  //   // left: -55px;
  //   // position: relative;
  // }

  .partial-workflow {
    .timeline-item {
      &:last-of-type {
        padding-bottom: 24px;

        &::after {
          bottom: 0;
        }

        .timeline-subitem:not(.empty-subitem) {
          &:last-of-type {
            &::after {
              height: 100%;
            }
          }
        }
      }

      .timeline-new-subitem-dropdown {
        left: -55px;
        bottom: -12px;
        position: relative;
        width: 0;
        height: 0px;
        display: block;

        &.prev {
          top: -8px;
          bottom: auto;
        }

        &.same {
          bottom: 18px;
        }
      }

      .timeline-new-subitem {
        left: -12px;
        top: -12px;
      }
    }

    .timeline-subitem {
      &::after {
        background: #e0e0e0;
      }
      li {
        &::before {
          top: 25px;
        }

        .subitem-icon {
          top: 23px;

          &.failed,
          &.completed,
          &.custom-icon {
            top: 12px;
          }
        }
      }
    }
    .step-title {
      display: flex;
      align-items: center;
      column-gap: 32px;
      flex-wrap: wrap;
      row-gap: 16px;
    }
  }

  .timeline-subitem li {
    &::before {
      background: $qm-gray-lighter;
    }
    .subitem-icon {
      background: $qm-gray-lighter;
    }
    .custom-icon {
      border-color: $qm-gray-lighter;
      color: $qm-color-text-secondary;
      svg {
        width: 22px;
        height: 22px;
        padding: 3px;
      }
    }
  }

  .inspector-container {
    display: flex;
    align-items: center;
    column-gap: 48px;
  }

  .timeline-new-subitem-button {
    border-color: $qm-gray-lighter;
  }

  .step-title {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .step-subtitle {
    font-size: 14px;
    font-family: 'MaisonNeue-Book';
    font-weight: 300;
    color: $qm-color-text-secondary;
    line-height: 18px;

    .invalid-feedback {
      margin-bottom: 0;
    }

    .edit-custom-qty-button {
      margin-left: 0.5em;
      font-size: 0.85em;
      box-shadow: none; // Fix parasitic shadow after modal close
    }
  }

  .text-separator {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    color: $qm-color-text-tertiary;
  }

  .subitem-container {
    display: flex;
    column-gap: 48px;
    align-items: center;
    min-height: 40px;
    flex-wrap: wrap;
    row-gap: 24px;
    padding-right: 32px;

    .subitem-header {
      margin: 8px 0;
      .reinspection-label {
        margin-top: 0.25em;
        .pill {
          font-size: 0.75em;
          justify-content: center;
        }
      }
    }
  }

  .inspection-info {
    display: flex;
    align-items: center;

    .inspection-info-content {
      margin-left: 8px;
    }
  }

  .previous-decisions-container + .decision-container {
    margin-top: $qm-margin-s;
  }
}
