@import 'src/styles/variables/_colors.scss';

.status-progress-panel {
  .progressContainer {
    width: 100%;

    $_col-b: $qm-color-primary;
    $_col-y: $qm-color-warning;
    $_col-r: $qm-color-error;

    .progressBar {
      .selected {
        &:nth-child(1) {
          background: $_col-r;
          background: linear-gradient(
            5deg,
            $_col-r 0%,
            lighten($_col-r, 25%) 100%
          );
        }
        &:nth-child(2) {
          background: $_col-y;
          background: linear-gradient(
            5deg,
            $_col-y 0%,
            lighten($_col-y, 25%) 100%
          );
        }
        &:nth-child(3) {
          background: $_col-b;
          background: linear-gradient(
            5deg,
            $_col-b 0%,
            lighten($_col-b, 25%) 100%
          );
        }
      }
    }
  }

  .progress-info {
    color: $qm-color-text;
    font-size: 0.85rem;
    margin-top: 0.5rem;
  }
}
