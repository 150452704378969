@import 'src/styles/variables/_sizes';
@import 'src/styles/variables/_colors';

.inspection-plans-bulk-results-page {
  > .error-bag {
    margin-top: 1em;
    line-height: 1.2;

    .error-item {
      margin-bottom: 0.5em;
    }
  }

  .ipb-bulk-results-item {
    h3 {
      margin-top: 1em;
      padding: 0.15em 0.25em;
      background: $qm-gray-light;
    }

    > .error-bag {
      margin-top: 1em;
      line-height: 1.2;

      .error-item {
        margin-bottom: 0.5em;
      }
    }

    &.is-single h3 {
      display: none;
    }
  }

  .tabs-container {
    margin-left: -$qm-padding-m;
    margin-right: -$qm-padding-m;
    padding-left: $qm-padding-m;
    padding-right: $qm-padding-m;
    border-bottom: 1px solid $qm-gray-lighter;
    padding-bottom: 0;

    .tabs-item {
      line-height: 1.2em;

      svg {
        height: 1.2em;
        width: 1em;
        vertical-align: bottom;
        margin-right: $qm-margin-xs;
      }

      &:not(.disabled) {
        &.success {
          color: $qm-color-text-success;
          border-bottom-color: $qm-color-text-success;
        }
        &.skipped,
        &.no_tags,
        &.plan_exists,
        &.user_plan_exists,
        &.plan_needs_review {
          color: $qm-color-text-warning;
          border-bottom-color: $qm-color-text-warning;
        }
        &.failed {
          color: $qm-color-error;
          border-bottom-color: $qm-color-error;
        }
      }
    }
  }

  .bulk-inspection-plans-results-header {
    margin-top: $qm-margin-s;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  h4 {
    margin-top: $qm-padding-m;
  }

  h5 {
    font-family: MaisonNeue-DemiBold;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  .results-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    gap: $qm-margin-m;
    margin-top: $qm-margin-m;

    .plan-result-item {
      &.success {
        color: $qm-color-text-success;
      }
      &.skipped,
      &.no_tags,
      &.plan_exists,
      &.plan_needs_review,
      &.user_plan_exists {
        color: $qm-color-text-warning;
      }
      &.failed {
        color: $qm-color-error;
      }
    }

    line-height: 1.25em;

    svg {
      height: 1.25em;
      width: 1em;
      vertical-align: bottom;
    }
  }

  .result-container {
    border-radius: $qm-border-radius-s;
    border: 1px solid $qm-gray-lighter;
    padding: 1em;

    .title {
      margin-bottom: 1em;
    }

    li + li {
      margin-top: $qm-padding-ms;
    }
  }

  .empty-plan-list {
    margin-top: $qm-margin-s;
  }
}
