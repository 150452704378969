@import 'src/styles/variables/_colors.scss';

.settings-import-question-translations-page {
  font-family: MaisonNeue-Medium;

  > p {
    max-width: 408px;
  }

  > .document {
    display: inline-block;
    text-align: center;
    margin-top: 24px;
    margin-bottom: 64px;

    a {
      text-decoration: none;
    }

    .iconWrapper {
      padding: 1em;
      background-color: $qm-color-selected;
      display: inline-block;
      border-radius: 5px;
      margin: 0.8em;

      > svg {
        color: white;
        font-size: 2em;
      }
    }
  }
}
