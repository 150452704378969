@import 'src/styles/variables/colors';
@import 'src/styles/variables/sizes';

.perform-inspection-page {
  .inspection-details {
    > * {
      gap: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    > * + * {
      margin-top: 24px;
    }
  }

  .inspection-actions {
    margin-top: 40px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  h4 {
    margin-bottom: 24px;
  }

  .photo-list-container {
    .qm-upload-button {
      margin-top: 24px;
    }

    .photo-list {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }
  }

  .documents-container {
    .form-label {
      margin-bottom: $qm-margin-s;
    }

    .qm-upload-button {
      margin-top: 24px;
    }

    .document-list {
      display: flex;
      flex-direction: column;

      > * + * {
        margin-top: $qm-padding;
      }

      .document {
        .form-group {
          display: flex;
          flex-direction: row;
          align-items: center;

          .qm-input-container {
            margin-left: $qm-margin-xs;
            width: 280px;
          }

          .delete-button {
            margin-left: $qm-margin-m;
          }
        }
      }
    }
  }

  .reference-documents-container,
  .asset-reference-documents-container {
    .document-list {
      display: flex;
      flex-wrap: wrap;
      gap: $qm-margin-m;
    }
  }

  .answer-photo {
    position: relative;
    display: inline-block;

    img {
      border-radius: 4px;
      width: 124px;
      height: 124px;
      object-fit: cover;
      background-color: #ccc;
    }

    .remove-button {
      border: none;
      background: none;
      cursor: pointer;
      position: absolute;
      top: -8px;
      right: -8px;
      height: 16px;

      svg {
        height: 16px;
      }
    }
  }

  .question-cell {
    white-space: pre-wrap;
  }

  .questions-table {
    p.unsupported-description {
      font-family: 'MaisonNeue-Book';
      font-size: 14px;
      font-weight: 300;
      max-width: unset;
    }
  }

  .perform-inspection-form {
    margin-top: 24px;

    > * + * {
      margin-top: 24px;
    }
  }

  .error-bag {
    margin-top: 12px;
  }

  .perform-inspection-actions {
    margin-top: 24px;
  }

  .inspection-summary-row {
    width: 500px;

    .inspection-summary {
      resize: vertical;
    }
  }

  &.is-vendor-survey {
    .qm-question-group .questions-table {
      .table-header .sample-size-cell .value {
        visibility: hidden;
      }

      .qm-question-detail .sample-size-cell {
        visibility: hidden;
      }
    }
  }
}
