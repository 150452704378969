@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.system-settings-page {
  display: flex;
  flex-direction: column;

  .row {
    padding: 24px 48px 0 48px;

    &:first-child {
      padding-top: 48px;
    }

    &:last-child {
      padding-bottom: 48px;
    }
  }

  .row-header {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .header-actions {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      > :not(:first-child) {
        margin-left: 0.5em;
      }
    }
  }

  .system-settings-users-summary {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .row-details {
    h3 {
      margin-bottom: 0.5em;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;

      .qm-button {
        margin-left: 0.5em;
      }
    }
  }

  .system-settings-users-activity-list {
    > ol > li:not(:first-child) {
      margin-top: 1.5em;
    }

    .user-item {
      .user-item-header {
        background: $qm-gray-light;
        padding: 0.25em 0.5em;

        h4 {
          font-size: 1rem;
          line-height: 1.2;
        }
      }

      .user-item-details {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: space-between;
        padding: 0.25em 0.5em 0 0.5em;

        .user-item-block {
          flex: 0 0 48%;

          h5 {
            font-size: 1rem;
            line-height: 1.2;
            border-bottom: 1px solid $qm-color-bg;
            padding-bottom: 0.1em;
            margin-bottom: 0.25em;
          }

          ol {
            li {
              &:not(:first-child) {
                margin-top: 0.25rem;
              }

              &.user-action-item,
              &.user-job-item {
                font-size: 0.75rem;
                border-left: 12px solid $qm-color-bg;
                padding-left: 0.25em;
              }
            }
          }

          &.user-item-block-latest-actions {
            .user-action-item {
              .url {
                font-weight: bolder;
              }
            }
          }

          &.user-item-block-active-jobs {
            .user-job-item {
              .info {
                font-weight: bolder;
              }
            }
          }
        }
      }
    }
  }
  .system-settings-db-stats {
    .qm-table-container {
      .db-stats-table {
        .table-cell {
          padding: 0.25em 0.5em;
        }
      }
    }
  }
}

.qm-modal-portal .qm-modal-overlay .qm-modal {
  &.set-maintenance-mode-modal {
    .body {
      padding-left: 40px;
      padding-right: 40px;

      p:not(:first-child) {
        margin-top: 0.5em;
      }

      .qm-input-container {
        width: 5em;
      }
    }

    .footer {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-end;

      > :not(:first-child) {
        margin-left: 1em;
      }
    }
  }
}
