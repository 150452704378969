@import 'styles/variables/_colors';
@import 'styles/variables/_sizes';

.auto-build-plan-form {
  h3 {
    font-family: 'MaisonNeue-DemiBold';
    font-size: 28px;
    font-weight: 600;
  }

  .auto-build-description-container {
    p {
      margin-bottom: 24px;
    }
  }

  .selected-resources-summary {
    font-size: 14px;
    margin-top: 16px;
    display: flex;
    align-items: center;
    column-gap: 16px;
    padding: 4px;

    &.is-invalid {
      width: 300px;
      border: solid 1px $qm-color-error;
      border-radius: 4px;
    }

    .summary-item {
      display: flex;
      align-items: center;
      column-gap: 4px;

      svg {
        width: 14px;
        height: 14px;
      }
    }
  }

  .auto-build-description-container,
  .override-plans-container,
  .inspection-types-info-container {
    max-width: 424px;
  }

  .field-description {
    font-size: 14px;
    font-family: 'MaisonNeue-Book';
    color: $qm-color-text-secondary;
    font-weight: 300;
    margin-top: 12px;
  }

  .auto-build-param {
    display: flex;
    align-items: flex-start;
    column-gap: 16px;
    margin-bottom: 12px;
  }

  .inspection-type {
    width: 180px;
  }

  .aql-container {
    display: flex;
    column-gap: 16px;
  }
  .aql-major,
  .aql-minor,
  .aql-functional,
  .validity-range {
    width: 90px;
  }
  .aql-level {
    width: 120px;
  }

  .defects-container {
    width: 167px;

    .input-info {
      display: none;
    }
    .critical-defects-input {
      width: 66px;
    }

    .critical-defects-switch {
      margin-left: 8px;
    }
  }
  .validity-container {
    .qm-input-container {
      width: 56px;
      margin-right: $qm-margin-xs;

      .input-info {
        display: none;
      }
    }

    .select-input {
      flex: 1;
    }
  }

  .delete-param-container {
    display: flex;
    align-items: flex-start;
  }

  .add-inspection-type {
    margin-top: 24px;
  }
  .error-bag {
    margin-top: 8px;
  }

  .form-row {
    margin-bottom: 40px;
  }
  .flex {
    display: flex;
    align-items: center;
  }

  .qm-select__single-value,
  .qm-select__value-container {
    margin: 0;
    padding: 0;
  }
  .qm-select__indicator {
    padding: 2px;
  }

  .last-auto-build-result {
    margin-bottom: 32px;

    .result-footer {
      margin: 8px 0;
      display: flex;
      align-items: center;
      column-gap: 32px;
    }

    .view-results {
      margin-top: 16px;
    }
  }
}
.auto-build-progress {
  width: 100%;
  height: 300px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  background: $qm-color-input;
  margin: 8px;
  padding-top: 75px;
  padding-bottom: 75px;

  .status-progress-panel {
    flex: 0 0 75%;
    color: $qm-color-selected;
    text-align: center;
  }

  .status-processing-panel {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    row-gap: 16px;

    .progress-icon {
      width: 48px;
      height: 48px;
      border: 2px solid $qm-color-primary;
      border-radius: 100%;
      display: inline-block;
      border-top: 5px solid transparent;
      animation: load-animate infinite linear 1s;

      @keyframes load-animate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(180deg);
          opacity: 0.35;
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
  }

  .description {
    font-size: 14px;
    color: $qm-color-text-secondary;
  }

  .error-bag {
    .error-item:last-child {
      margin-bottom: 0;
    }
  }
}

.auto-build-plan-form-linkable-resources-modal {
  .body {
    z-index: 10;
  }
  .header {
    z-index: 11;
  }

  .filters-container {
    .filter {
      .search-input-container .search-input {
        width: 200px;
      }
    }
  }
}
