@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.workflows-form {
  .qm-input-container,
  .select-input,
  .qm-textarea-container {
    max-width: 440px;
  }

  .form-row {
    margin-bottom: 32px;
  }

  .qm-textarea {
    height: $qm-textarea-height;
  }

  p.source-description {
    max-width: 400px;
    margin-top: 1em;
    font-size: 14px;
    font-family: MaisonNeue-Medium;
    color: $qm-color-text-tertiary;
  }

  .source-container {
    display: flex;
    align-items: center;
    margin-bottom: 0;

    .qm-resource-select-button-container {
      width: 348px;
    }

    .delete-source {
      align-self: flex-start;
      margin-top: 14px;
      margin-left: $qm-margin-m;
    }
  }

  .timeline-item {
    &:first-of-type {
      overflow: visible;
    }

    &.has-label {
      .delete-source {
        margin-top: 38px;
      }
    }

    padding-bottom: 60px;
  }

  .timeline-subitem {
    padding: 0;
    margin-top: 40px;

    &:last-of-type {
      padding: 0;
    }
  }

  .step-container {
    display: flex;
    flex-wrap: wrap;
    gap: $qm-padding;

    .step-item {
      &:not(:last-of-type) {
        // margin-right: 32px;
      }
    }

    .select-input {
      width: 275px;
    }
  }

  .delete-inspection-pair {
    margin-top: $qm-margin-m + 2px;
    margin-left: $qm-margin-m;
  }

  .add-inspection-pair {
    margin-top: 20px;

    &::after {
      width: 1px;
      display: block;
      background: $qm-color-primary;
      height: 100%;
      content: '';
      position: absolute;
      top: 0;
      left: 20px;
    }
  }

  .custom-qty-container {
    margin-top: 0.75em;

    .flex {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;

      .qm-input-container {
        margin-right: 0.25em;

        .input-info {
          display: none;
        }
      }
    }
  }

  .checkbox-container {
    margin-top: 16px;
    display: flex;
    flex-direction: row;
    gap: $qm-margin-m;
  }

  .submit-container {
    margin-top: 72px;
  }

  .error-bag {
    margin-top: 8px;
  }
}
