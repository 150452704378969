@import 'styles/variables/_sizes';
@import 'styles/variables/_colors';

article.inspection-report {
  width: 8.5in;
  font-size: 9pt;

  h2 {
    font-size: 20pt;
    text-align: center;
    color: $qm-color-text;
    font-family: MaisonNeue-DemiBold;
    font-weight: 600;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  h3 {
    font-size: 20pt;
  }

  h4 {
    font-size: 14pt;
    line-height: 1em;
  }

  h5 {
    font-size: 1em;
  }

  .company-name {
    margin-bottom: 0.5em;
  }

  .company-address,
  .company-contact {
    font-size: 9pt;
    font-family: MaisonNeue-Book;
    font-weight: 300;
    color: $qm-color-text-secondary;

    > * {
      line-height: 1.5em;
    }
  }
  .company-phone {
    &::after {
      display: inline-block;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-phone-call'%3E%3Cpath d='M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z'%3E%3C/path%3E%3C/svg%3E");
      vertical-align: top;
      margin-left: 0.5em;
    }
  }
  .company-email {
    &::after {
      display: inline-block;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-mail'%3E%3Cpath d='M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z'%3E%3C/path%3E%3Cpolyline points='22,6 12,13 2,6'%3E%3C/polyline%3E%3C/svg%3E");
      vertical-align: top;
      margin-left: 0.5em;
    }
  }

  header {
    background: rgb(247, 247, 247);
    border-radius: 0.5rem;
    border: 1pt solid rgb(219, 219, 219);
    padding: 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;

    h3 {
      flex: 0;
      flex-basis: 100%;
    }

    .company-address,
    .company-contact {
      flex: 1;
    }

    .company-contact {
      text-align: right;
    }
  }
  .summary-notes {
    width: 50ch;
    margin: 0 auto;

    h4 {
      font-size: 9pt;
      color: rgb(82, 82, 82);
      margin-bottom: 0.5em;
      font-family: MaisonNeue-Bold;
      font-weight: bold;
    }

    p {
      color: rgb(82, 82, 82);
      font-family: MaisonNeue-Book;
      font-weight: 300;
      white-space: pre-wrap;
      margin-bottom: 2rem;
    }
  }

  .sum-failed-aql-questions {
    color: rgb(82, 82, 82);
    font-family: MaisonNeue-Book;
    font-weight: 300;
    text-align: center;

    margin-top: 1em;
    margin-bottom: 2em;

    b {
      color: rgb(22, 22, 22);
      font-family: MaisonNeue-Bold;
      font-weight: 600;
    }
  }

  summary {
    border-radius: 0.5rem;
    border: 1pt solid rgb(219, 219, 219);

    > div {
      padding: 1.5rem;
    }

    > div + div,
    .summary-aql-overview {
      border-top: 1pt solid rgb(219, 219, 219);
    }

    .inspection-conclusion {
      font-weight: bold;
      line-height: 1.125rem;
      margin-top: 0.25rem;
      font-size: 10pt;

      &::before {
        font-weight: normal;
        content: 'Conclusion: ';
        white-space: pre;
      }
    }

    .inspection-result {
      font-weight: bold;
      // line-height: 1.125rem;
      margin-top: 0.25rem;
      font-size: 10pt;

      &::before {
        font-weight: normal;
        content: 'Inspection result: ';
        white-space: pre;
      }
    }

    .labelled-value {
      font-size: 10pt;
      display: inline-block;

      .label {
        font-family: MaisonNeue-Book;
        font-weight: 300;
        &::after {
          content: ': ';
        }
      }

      .value {
        font-family: MaisonNeue-Bold;
        font-weight: bold;
      }
    }

    .inspection-aql-level {
      margin-top: 0.25rem;
    }

    .summary-attributes {
      font-family: MaisonNeue-Book;
      font-weight: 300;

      h5 {
        font-size: 1rem;
        margin-bottom: 1em;
      }

      .attribute-grid {
        font-size: 10pt;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 2em;

        b {
          font-family: MaisonNeue-Bold;
          font-weight: 600;
        }
      }
    }

    .summary-title {
      display: flex;
      flex-direction: row;

      .summary-right,
      .summary-left {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }

      .summary-right {
        margin-left: auto;
        text-align: right;
      }

      .inspection-id {
        font-size: 9pt;
        font-family: MaisonNeue-Book;
        font-weight: 300;
        color: $qm-color-text-secondary;
        margin-left: 1em;
        &::before {
          content: 'ID: ';
        }
      }

      .inspection-date {
        margin-top: 0.5em;
        font-family: MaisonNeue-Book;
        font-weight: 300;
        color: $qm-color-text-secondary;
      }

      .work-object-internal-id,
      .work-object-external-id {
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;

        &::before {
          font-family: MaisonNeue-Book;
          font-weight: 300;
          color: $qm-color-text-secondary;
        }
      }

      .work-object-external-id {
        margin-top: 0.5em;
      }

      .work-object-internal-id::before {
        content: 'Work object ID: ';
      }
    }

    .summary-items {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 2rem;
    }
  }

  .asset-info {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    .flex-row {
      display: flex;
      gap: 1rem;
      flex-direction: row;
    }

    .asset-picture {
      img {
        height: 5em;
        width: 5em;
        object-fit: cover;
        border-radius: 4px;
      }
    }

    .asset-description {
      display: flex;
      flex-direction: column;
      gap: 0.5em;

      .asset-id {
        &::before {
          content: 'ID: ';
        }
      }

      .asset-name {
        color: $qm-color-text;
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
        font-size: 10pt;
        max-width: 25ch;
        line-height: 1.5em;
      }
    }
  }

  .source-info {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    .source-name {
      color: $qm-color-text;
      font-family: MaisonNeue-DemiBold;
      font-weight: 600;
      font-size: 10pt;
      max-width: 25ch;
      line-height: 1.5em;
    }

    .source-type {
      color: $qm-color-text-tertiary;
      font-family: MaisonNeue-Medium;
      font-weight: 500;
    }

    .source-id {
      &::before {
        content: 'ID: ';
      }
    }
  }

  .inspector-info {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;

    .inspector-avatar {
      height: 3em;
      width: 3em;
      border-radius: 50%;
      object-fit: cover;
    }

    .inspector-description {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .inspector-name {
      color: $qm-color-text;
      font-family: MaisonNeue-DemiBold;
      font-weight: 600;
      font-size: 10pt;
      line-height: 1.5em;
    }
    .inspector-company {
      color: $qm-color-text-tertiary;
      font-family: MaisonNeue-Medium;
      font-weight: 500;
    }
  }
  .summary-aql-overview {
    border-spacing: 2em;
    border-collapse: separate;
    width: 100%;
  }
  .defect-overview-table,
  .aql-overview-table {
    table-layout: auto;
    width: 100%;
    color: $qm-color-text-secondary;

    caption {
      color: $qm-color-text;
      font-family: MaisonNeue-DemiBold;
      font-weight: 600;
      font-size: 1rem;
      text-align: left;
      padding: 0.25rem 0.75rem;
      padding-bottom: 0.75rem;
      padding-left: 0;
    }

    td,
    th {
      padding: 0.25rem 0.75rem;
      border-collapse: collapse;
      text-align: center;

      &:first-child {
        text-align: left;

        padding-left: 0;
      }
    }

    th {
      padding-bottom: 1rem;
    }
  }

  .defect-overview-table {
    td,
    th {
      &:first-child {
        // width: 24ch;
      }
    }
  }

  .aql-overview-table {
    td,
    th {
      padding-left: 0.4rem;
      padding-right: 0.4rem;

      &:first-child {
        // width: 13ch;
        white-space: nowrap;
      }
    }
  }

  .question-group {
    table-layout: auto;
    width: 100%;
    border-collapse: separate;

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    h4 {
      display: block;
    }

    td {
      padding: 1.25rem;
    }

    tr.answer-summary td {
      border-top: 1pt solid rgb(219, 219, 219);
    }

    td:first-child {
      border-left: 1pt solid rgb(219, 219, 219);
    }

    td:last-child:not(.answer-body) {
      border-right: 1pt solid rgb(219, 219, 219);
    }

    tr:first-child {
      td {
        page-break-inside: avoid;
        border-top: 1pt solid rgb(219, 219, 219);
      }

      td:first-child {
        border-top-left-radius: 0.5rem;
      }

      td:last-child {
        border-top-right-radius: 0.5rem;
      }
    }

    tr:last-child {
      td {
        border-bottom: 1pt solid rgb(219, 219, 219);
      }

      td:first-child {
        border-bottom-left-radius: 0.5rem;
      }

      &.answer-photos {
        td:last-child {
          border-bottom-right-radius: 0.5rem;
        }
      }
    }

    tr:nth-last-child(2):not(.show-photos) {
      td:last-child {
        border-bottom-right-radius: 0.5rem;
        border-bottom: 1pt solid rgb(219, 219, 219);
      }
    }

    .question-order {
      width: 0;
      padding-right: 0;
      &::before {
        content: '#';
      }
    }
  }

  p {
    max-width: 50ch;
    font-size: 9pt;
    line-height: 1.5em;
  }

  .answer-summary {
    break-inside: avoid;
  }

  .answer-details {
    td {
      padding-top: 0;
    }
  }

  .question-name {
    font-family: MaisonNeue-DemiBold;
    font-weight: 600;
    break-inside: avoid;
    white-space: pre-wrap;
  }

  .question-notes {
    margin-top: 0.5rem;
    white-space: pre-wrap;
  }

  .question-type {
    font-family: MaisonNeue-DemiBold;
    font-weight: 600;

    &::before {
      content: 'Type: ';
      font-family: MaisonNeue-Book;
      font-weight: 300;
      color: $qm-color-text-secondary;
    }
  }

  .actual-answer {
    font-family: MaisonNeue-DemiBold;
    font-weight: 600;

    &::before {
      content: 'Answer: ';
      font-family: MaisonNeue-Book;
      font-weight: 300;
      color: $qm-color-text-secondary;
    }
  }

  table.asset-units-table {
    td,
    th {
      padding: 0.25rem 0.5rem;
      font-family: MaisonNeue-Book;
      font-weight: 300;
      color: rgb(82, 82, 82);
      text-align: center;

      &:first-child {
        padding-left: 0;
        text-align: left;
      }

      &:last-child {
        padding-right: 0;
      }
    }

    tr {
      &:first-child td {
        padding-top: 0;
      }
      &:last-child td {
        padding-bottom: 0;
      }
    }

    .measurement-unit {
      font-family: MaisonNeue-Bold;
      font-weight: 600;
      color: rgb(22, 22, 22);
    }
  }

  .sample-size {
    font-size: 9pt;

    > * {
      margin-top: 10pt;
    }
    .calculated-sample-size {
      font-family: MaisonNeue-DemiBold;
      font-weight: 600;
      text-align: right;

      &::before {
        content: 'Sample size: ';
        font-family: MaisonNeue-Book;
        font-weight: 300;
        color: $qm-color-text-secondary;
      }
    }

    .aql-override-status {
      color: rgb(168, 168, 168);
      font-family: MaisonNeue-Book;
      font-size: 9pt;
      font-style: italic;
      font-weight: 300;
      letter-spacing: 0px;
      text-align: right;
    }

    .aql-standards {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 0.5em;

      .defect-weight {
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
        text-align: right;

        &::before {
          content: attr(data-weight) ': ';
          font-family: MaisonNeue-Book;
          font-weight: 300;
          color: $qm-color-text-secondary;
        }
      }

      .aql-level {
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
        text-align: right;

        &::before {
          content: 'AQL: ';
          font-family: MaisonNeue-Book;
          font-weight: 300;
          color: $qm-color-text-secondary;
        }
      }
    }
  }

  .single-measurements {
    display: flex;
    flex-direction: row;
    column-gap: 1em;
    flex-wrap: wrap;
    line-height: 1.25rem;
    margin-top: 0.25rem;

    &::before {
      content: 'Measurements: ';
      font-family: MaisonNeue-Book;
      font-weight: 300;
      color: $qm-color-text-secondary;
    }

    .measure {
      &.exceeded {
        font-family: MaisonNeue-DemiBold;
        font-weight: 600;
      }
    }
  }

  .actual-defects {
    page-break-inside: avoid;

    .defect + .defect {
      margin-top: 0.5em;
    }
  }

  .defect-notes {
    white-space: pre-wrap;
  }

  figure.photo {
    height: 14em;
    width: 13em;
    display: inline-block;
    margin-right: 1em;
    margin-bottom: 1em;

    img {
      height: calc(100% - 1em);
      width: 100%;
      object-fit: cover;
    }

    figcaption {
      text-align: center;
      color: $qm-color-text-tertiary;
      font-style: italic;
      font-family: MaisonNeue-BookItalic;
      font-weight: 300;
      white-space: pre-wrap;
    }
  }

  &.is-vendor-survey {
    .summary-aql-overview {
      display: none;
    }

    .question-group .answer-summary .sample-size > div {
      display: none;
    }
  }
}
