@import 'src/styles/variables/_colors.scss';

.CSVPlaceholder {
  font-family: 'Maison Neue';
  box-sizing: border-box;
  height: 300px;
  width: 100%;
  border: 2px dashed #e0e0e0;
  border-radius: 6px;
  background-color: #f4f4f4;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $qm-color-text;

  .state-select {
    width: 100%;
    margin: auto 0;
    padding: 50px 0;

    &.dragging {
      background-color: lightgray;
    }

    form > * {
      margin: 8px 0;
    }

    input[type='file'] {
      display: none;
    }

    .iconWrapper {
      border-radius: 100%;
      display: inline-block;
      padding: 20px;
      background-color: rgba($qm-color-primary, 0.1);
    }

    .browse {
      background-color: $qm-color-selected;
      font-weight: 600;
      font-size: 14px;
      padding: 7px 16px;
      color: white;
      border: 0px;
      border-radius: 4px;
      outline-width: 0;
      width: fit-content;
      margin: auto;
    }
  }

  .stateUpload {
    > * {
      margin: 20px 0;
    }

    .title {
      color: $qm-color-selected;
      font-size: 20px;

      .fileName {
        font-weight: 600;
      }
    }

    .mb {
      font-size: 14px;
      color: #525252;
    }

    .progressContainer {
      width: 287px;
    }

    .percent {
      font-weight: 500;
    }
  }

  .stateAnalyzing {
    flex: 0 0 75%;
    color: $qm-color-selected;

    .loader {
      padding: 21px;
      background: rgba($qm-color-primary, 0.1);
      margin: 8px;
      border-radius: 42px;
      display: inline-block;
    }
  }
}
