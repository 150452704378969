@import '~styles/variables/sizes';
@import '~styles/variables/colors';

.workflows-detail-page {
  .workflow-header {
    display: flex;
    justify-content: space-between;
  }

  h1 {
    font-size: 42px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  h2 {
    font-family: 'MaisonNeue-Book';
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    margin-right: $qm-margin-m;
  }
  .header-info {
    display: flex;
    column-gap: 24px;
    margin-bottom: 24px;

    p {
      font-family: 'MaisonNeue-Medium';
      font-size: 14px;
      font-weight: 500;
      color: $qm-color-text-tertiary;
      margin: 0;
    }
  }

  .page-description {
    font-family: 'MaisonNeue-Book';
    font-size: 16px;
    font-weight: 300;
    max-width: 450px;
    margin-bottom: $qm-margin-l;
  }

  a {
    font-family: 'MaisonNeue-Medium';
    font-weight: 500;
    color: $qm-color-text-link;
    text-decoration: none;
  }

  .step-title {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .step-subtitle {
    font-size: 14px;
    font-family: 'MaisonNeue-Book';
    font-weight: 300;
    color: $qm-color-text-secondary;
    line-height: 18px;
    height: 18px;
    display: inline-block;

    .status-pill {
      display: inline-flex;
    }

    &.step-subtitle-block {
      display: block;
    }
  }
  .text-separator {
    font-family: 'MaisonNeue-Medium';
    font-size: 16px;
    font-weight: 500;
    color: $qm-color-text-tertiary;
  }

  .info-message {
    margin-top: 48px;
    text-align: center;
  }

  .work-objects-table-title {
    margin: 64px 0 24px 40px;
    display: flex;
    align-items: center;
  }
}
